import React from 'react';

import { Box, Card, CardBody, Divider, Flex, HStack, VStack } from '@chakra-ui/react';
import millify from 'millify';
import Link from 'next/link';
// import { useRouter } from 'next/navigation';
import { MdOutlineHomeWork, MdOutlineVideoCameraFront } from 'react-icons/md';

// import { LoadingButton } from '@components/custom-buttons';
import RoundedBadge from '@components/custom-badge/RoundedBadge';
import { CustomText } from '@components/Custom-text';
import { CompanyLogo } from '@components/logo/CompanyLogo';
import Rating from '@components/rating/Rating';
import { Mentor } from '@modules/auth/types';
import { paths } from '@routes/path';

interface MentorCardProps {
    mentorInfo: Mentor;
}

/*
const MentorCard_ = ({ mentorInfo }: MentorCardProps) => {
    const router = useRouter();
    return (
        <Card
            display="flex"
            variant="outline"
            size="sm"
            _hover={{
                borderColor: 'primary.light',
                boxShadow: '1px 3px 10px gray',
            }}
        >
            <CardBody px={{ base: '5px', sm: '5px', md: '12px' }}>
                <VStack width="100%">
                    <Box
                        height="220px"
                        minH="220px"
                        width="100%"
                        position="relative"
                        borderRadius="6px"
                        style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0.45)), url(${mentorInfo.profileImageUrl})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    >
                        <VStack
                            alignItems="self-start"
                            gap={0}
                            position="absolute"
                            bottom={0}
                            left={2}
                        >
                            <CustomText color="white" weight={500} size="md">
                                {mentorInfo?.name ?? '-'}
                            </CustomText>
                            <CustomText color="white" weight={300}>
                                {`${mentorInfo?.currentRole ?? '-'}, ${mentorInfo?.domain?.name ?? '-'
                                    } `}
                            </CustomText>
                        </VStack>
                    </Box>
                    <Flex w="100%" justifyContent="space-between" mb={3}>
                        <VStack w="100%" alignItems="flex-start" gap={0}>
                            <CustomText color="mainText" size="xs">
                                Session Completed
                            </CustomText>
                            <CustomText color="primary.dark" size="sm" weight={500}>
                                {mentorInfo.sessionsCompleted ?? 0} Sessions
                            </CustomText>
                        </VStack>
                        <VStack w="100%" gap={0} alignItems="end">
                            <CustomText color="mainText" size="xs">
                                Reviews {`(${millify(mentorInfo?.totalReviews ?? 0)})`}
                            </CustomText>
                            <HStack alignItems="center" width="100%" gap={1}>
                                <Rating rating={mentorInfo.totalRating ?? 0} />
                                <CustomText color="#ECC94B" size="sm" weight={500}>
                                    {mentorInfo.totalRating ?? 0}
                                </CustomText>
                            </HStack>
                        </VStack>
                    </Flex>
                    <Divider orientation="horizontal" />
                    <LoadingButton
                        buttonLabel="Book Session"
                        onClick={() =>
                            router.push(`${paths.general.findMentor}/${mentorInfo?.username}`)
                        }
                        isLoading={false}
                        loadingText="booking"
                        type="button"
                    />
                </VStack>
            </CardBody>
        </Card>
    );
};
*/

const MentorCard = ({ mentorInfo }: MentorCardProps) => {
    const shouldShowSessions = mentorInfo?.sessionsCompleted > 0;
    const shouldShowReviews = mentorInfo?.totalReviews > 0;
    let badgeLabel = '';
    if (mentorInfo?.sessionsCompleted === 0) {
        badgeLabel = 'New';
    } else if (mentorInfo?.totalRating >= 4) {
        badgeLabel = 'Top Rated';
    }
    const shouldShowBadge = Boolean(badgeLabel);
    return (
        <Link href={`${paths.general.findMentor}/${mentorInfo?.username}`}>
            <Card
                display="flex"
                variant="outline"
                size="sm"
                _hover={{
                    borderColor: 'primary.light',
                    boxShadow: '1px 1px 10px #DEDEDE',
                }}
                borderRadius={8}
            >
                <CardBody
                    px={{ base: '5px', sm: '5px', md: '8px' }}
                    py={{ base: '5px', sm: '5px', md: '8px' }}
                >
                    <VStack width="100%">
                        <Box
                            height="220px"
                            minH="220px"
                            width="100%"
                            position="relative"
                            borderRadius="8px"
                            style={{
                                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0.45)), url(${mentorInfo.profileImageUrl})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        >
                            {shouldShowBadge ? (
                                <Box position="absolute" top="10px" right="14px">
                                    <RoundedBadge>{badgeLabel}</RoundedBadge>
                                </Box>
                            ) : null}
                            <VStack
                                alignItems="self-start"
                                gap={0}
                                position="absolute"
                                bottom="16px"
                                left="16px"
                                paddingRight={5}
                            >
                                <CustomText color="white" weight={600} size="md">
                                    {mentorInfo?.name ?? '-'}
                                </CustomText>
                                <CustomText color="white" size="xs">
                                    {`${mentorInfo?.currentRole ?? '-'}, ${
                                        mentorInfo?.domain?.name ?? '-'
                                    } `}
                                </CustomText>
                            </VStack>
                        </Box>
                        <HStack minW={0} alignSelf="flex-start">
                            <div>
                                <MdOutlineHomeWork size={24} />
                            </div>
                            <CustomText color="mainText" size="sm" noOfLines={1}>
                                {mentorInfo?.latestExperience?.role ?? '-'}
                            </CustomText>
                        </HStack>
                        <HStack minW={0} alignSelf="flex-start">
                            <CompanyLogo
                                src={mentorInfo?.latestExperience?.logo?.url}
                                height={24}
                                width={24}
                            />
                            <CustomText color="mainText" size="sm" noOfLines={1}>
                                {mentorInfo?.latestExperience?.company ?? '-'}
                            </CustomText>
                        </HStack>
                        <Flex
                            w="100%"
                            justifyContent={shouldShowSessions ? 'space-between' : 'flex-start'}
                        >
                            {shouldShowSessions ? (
                                <HStack minW={0} mr={1}>
                                    <div>
                                        <MdOutlineVideoCameraFront size={24} />
                                    </div>
                                    <CustomText color="mainText" size="sm">
                                        {millify(mentorInfo?.sessionsCompleted)} Session
                                        {mentorInfo?.sessionsCompleted === 1 ? '' : 's'}
                                    </CustomText>
                                </HStack>
                            ) : null}
                            <HStack minW={0} gap={1}>
                                <CustomText color="mainText" size="sm" as="span" weight={500}>
                                    Experience:
                                </CustomText>
                                <CustomText color="mainText" size="sm" as="span">
                                    {mentorInfo?.totalExperience?.years ?? 1} Year
                                    {(mentorInfo?.totalExperience?.years ?? 1) === 1 ? '' : 's'}
                                </CustomText>
                            </HStack>
                        </Flex>
                        {shouldShowReviews ? (
                            <Flex w="100%" justifyContent="space-between">
                                <HStack minW={0}>
                                    <CustomText color="mainText" size="sm">
                                        Reviews
                                    </CustomText>
                                </HStack>
                                <HStack minW={0} flex={1} ml={1} justifyContent="flex-end">
                                    <Rating rating={mentorInfo.totalRating ?? 0} />
                                    <CustomText color="mainText" size="sm">
                                        {millify(mentorInfo.totalReviews ?? 0)}
                                    </CustomText>
                                </HStack>
                            </Flex>
                        ) : null}
                        <Divider orientation="horizontal" />
                        <Flex
                            w="100%"
                            h={9}
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <CustomText color="primary.light" size="sm" noOfLines={2}>
                                Book a Session
                            </CustomText>
                        </Flex>
                    </VStack>
                </CardBody>
            </Card>
        </Link>
    );
};

export default MentorCard;
