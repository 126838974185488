import { useMemo } from 'react';

import { Avatar } from '@chakra-ui/react';
import { PiBuildings } from 'react-icons/pi';

interface CompanyLogoProps {
    src?: string;
    height: number;
    width: number;
}

export const CompanyLogo = ({ src, height, width }: CompanyLogoProps) => {
    const url = useMemo(
        () => src?.replace('{W}', `${width * 3}`)?.replace('{H}', `${height * 3}`),
        [src, height, width]
    );
    return (
        <Avatar
            src={url}
            icon={<PiBuildings fontSize={`${height}px`} />}
            height={`${height}px`}
            width={`${width}px`}
            bg="white"
            textColor="mainText"
        />
    );
};
