import { SessionPayload, SessionResponse } from '@modules/bookings/types';
import { showToast } from '@slices/apiSlice';
import { baseApi } from '@store/baseQuery';

import { MentorFilters, Mentors } from '../types';

export const findMentors = baseApi.injectEndpoints({
    endpoints: builder => ({
        getMentors: builder.query<Mentors, MentorFilters>({
            query: filters => ({
                url: `/mentor`,
                method: 'GET',
                params: {
                    offset: filters.offset ?? 0,
                    limit: filters.limit ?? 8,
                    orderBy: filters.orderBy,
                    orderDirection: filters.orderDirection,
                    search: filters.search?.trim(),
                    domainId: filters.domainId,
                    skills: filters.skills,
                    interests: filters.interests,
                    hobbies: filters.hobbies,
                    placeOfOrigin: filters.placeOfOrigin,
                    totalRating: filters.totalRating,
                    totalExperienceMin: filters.totalExperienceMin,
                    totalExperienceMax: filters.totalExperienceMax,
                    fromDate: filters.fromDate,
                    toDate: filters.toDate,
                } as MentorFilters,
            }),
            providesTags: ['Mentors'],
            async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    dispatch(showToast({ variant: 'error', description: error?.error }));
                }
            },
        }),
        bookSession: builder.mutation<SessionResponse, SessionPayload>({
            query: body => ({
                url: '/sessions',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Sessions'],
            async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        showToast({
                            variant: 'success',
                            description: 'Session booked successfully',
                        })
                    );
                } catch (error) {
                    dispatch(showToast({ variant: 'error', description: error?.error }));
                }
            },
        }),
    }),
    overrideExisting: true,
});

export const { useGetMentorsQuery, useLazyGetMentorsQuery, useBookSessionMutation } = findMentors;
